
import { Box, Button, Card, CardContent, CircularProgress, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import theme from '../theme'
import React, { useEffect } from 'react'
import ResumeTitle from './ResumeTitle'
import ResumeThumbnail from './ResumeThumbnail'
import { DeleteForever, Edit, Folder } from '@mui/icons-material'
import useUser from '../Hooks/useUser'
import { httpOnGetResumeThumbnail } from '../Services/Http'
import ConfirmDialogBox from './ConfirmDialogBox'
import { useNavigate } from 'react-router-dom'


interface ResumeCardProps {
    resumeB62Id: string,
    fileName: string,
    resumeUpdatedAt: string,
    onRemoveResume: (resumeB62Id: string) => void,
    removeResumeInProgress: boolean,
    isDisabled: boolean,
    displayNavigateToResources: boolean,
}
  

const ResumeCard: React.FC<ResumeCardProps> = ({ resumeB62Id, fileName, resumeUpdatedAt,
  onRemoveResume, removeResumeInProgress, isDisabled, displayNavigateToResources
}) => {

  const [resumeThumbnail, setResumeThumbnail] = React.useState<string>("");

  const { userId } = useUser();

  const navigate = useNavigate();

  const [removeResumeDialogOpen, setRemoveResumeDialogOpen] = React.useState<boolean>(false);

  useEffect(() => {

    console.log("useEffect resumeB62Id:", resumeB62Id);

    if (resumeB62Id) {
      httpOnGetResumeThumbnail(resumeB62Id, userId)
      .then((res: any) => {
        setResumeThumbnail(res?.pdf_thumbnail || '');
      })
      .catch((e: any) => {setResumeThumbnail('')});
    } else {
      setResumeThumbnail('');
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resumeB62Id]);

  const editResume = (resumeB62Id: string)  =>  navigate(`/app/${resumeB62Id}/edit`);

  const navigateToResourcesView = (resumeB62Id: string)  =>  navigate(`/app/${resumeB62Id}/folder`);

  return (
    <Card
      sx={{ height: 1, alignContent: 'end' }}>
      <CardContent sx={{display: 'flex', flexDirection: 'column', height: '100%',
        '&:last-child': {
          pb: 2
        }
        }}>

        <Box pb={1}>
          <Box sx={{ display: 'flex', alignItems: 'center' }} pb={1}>
            <ResumeTitle fileName={fileName} resumeUpdatedAt={resumeUpdatedAt} />
          </Box>
        </Box>

        {/* <Divider /> */}

        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }} pb={1}>

          <Box pb={1} pt={1} sx={{ width: '40px' }}>
          </Box>

          <ResumeThumbnail thumbnailUrl={resumeThumbnail}
                            onClick={undefined} />

          <Box pb={1} pt={1} sx={{ alignSelf: 'end', width: '40px' }}>
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Tooltip title={'Edit original resume text.'} placement="top" arrow>
                    <IconButton color="primary" onClick={() => editResume(resumeB62Id)}
                      disabled={isDisabled || removeResumeInProgress || !resumeB62Id}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </Box>
            </Box>
            <Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                  <Tooltip title={'Delete the resume'} placement="top" arrow>
                    <IconButton 
                      color="error" 
                      onClick={() => {setRemoveResumeDialogOpen(true)}} 
                      disabled={isDisabled || removeResumeInProgress}
                    >
                      <DeleteForever />
                    </IconButton>
                  </Tooltip>

                  { removeResumeInProgress && <CircularProgress sx={{
                    marginTop: '10px',
                    width: '20px !important', height: '20px !important'}}/>
                  }
                </Box>
            </Box>
          </Box>
        </Box>

        { displayNavigateToResources && <>
          <Divider />

          <Box sx={{'display': 'flex', p: 1}}>
            <Tooltip title={'View previously added job descriptions and resumes'} placement="top" arrow>
              {/* <span>
                <IconButton color={'primary'}
                    onClick={() => {navigateToResourcesView(params.resumeB62Id!)}} disabled={isDisabled || removeResumeInProgress}><Folder/></IconButton>
              </span> */}
              <Button variant='outlined' color={'primary'} onClick={() => {navigateToResourcesView(resumeB62Id)}} disabled={isDisabled || removeResumeInProgress} startIcon={<Folder/>}>
                View Tailored Resumes
              </Button>
            </Tooltip>
          </Box>
        </>
        }

        <ConfirmDialogBox
          title="Please confirm"
          message="Please confirm you want to delete this resume!"
          open={removeResumeDialogOpen}
          primaryButton={{ label: 'Delete', onClick: () => {onRemoveResume(resumeB62Id);setRemoveResumeDialogOpen(false);} }}
          secondaryButton={{ label: 'Cancel', onClick: () => setRemoveResumeDialogOpen(false) }}
          fullWidth
          onClose={() => setRemoveResumeDialogOpen(false)}
        />


      </CardContent>
    </Card>
  )
}


export default ResumeCard;