// src/pages/Landing.tsx

import {
  Box,
  Button,
  Typography,
  Grid,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Build, Feedback, DesignServices } from '@mui/icons-material';
import FAQSection from '../Components/FAQSection';
import TestimonialsCarousel from '../Components/TestimonialsCarousel';
import HeroSection from '../Components/HeroSection';
import InfoBox1 from '../Components/InfoBox1';
import InfoBox2 from '../Components/InfoBox2';

const isWaitlistMode = !!process.env.REACT_APP_IS_WAITLIST_MODE;

// Define the type for the user prop
interface User {
  id: string | null;
  // Add other user properties as needed
}

interface LandingProps {
  user: User;
}


export default function Landing({ user }: LandingProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  const isAuthenticated = !!user.id;

  const handleGetStartedClick = () => {
    if (isAuthenticated) {
      navigate('/app');
    } else {
      navigate('/signup');
    }
  };

  return (
    <Box>
      {/* Hero Section */}

      <HeroSection
        handleGetStartedClick={handleGetStartedClick}
        buttonText={isAuthenticated ? "Go to your Dashboard" : "Get Started For Free"}
        isWaitlistMode={isWaitlistMode!}
      />

      {/* Informational Box 1 */}
      <InfoBox1 />

      {/* How It Works Section */}
      <Box
        textAlign="center"
        py={{ xs: 6, md: 10 }}
        sx={{
          width: '100%',
          minHeight: {
            xs: '20vh',    
            sm: '25vh',   
            md: '30vh',   
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', 
          alignItems: 'center', 
          background: `linear-gradient(180deg, 
            ${theme.palette.background.default} 0%, 
            ${theme.palette.augmentColor({
              color: theme.palette.secondary,
              mainShade: 'light',
            }).light}40 30%,  
            ${theme.palette.background.paper} 100%)`,
        }}
      >
        <Box  py={{xs:1,md:4}}>
          <Typography variant="h4" component="h2" gutterBottom color="textSecondary">
            How It Works
          </Typography>
        </Box>
        <Grid container spacing={4} justifyContent="center">
          {[
            {
              title: 'Step 1: Upload Your Resume (PDF Format)',
              description:
                'Your journey begins by uploading your master resume. This document should contain all your experience, skills, and education.',
            },
            {
              title: 'Step 2: Paste the Job Description You Want',
              description:
                'Our AI analyzes the job description, mapping the essential skills and qualifications needed for the role—allowing you to align your resume with what recruiters are looking for.',
            },
            {
              title: 'Step 3: Download Your Optimized Resume',
              description:
                'Within moments, our AI will enhance your resume to match keywords from the job description while ensuring your qualifications are accurately represented.',
            },
          ].map((step, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  maxWidth: 350,
                  mx: 'auto',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  borderRadius: 2,
                  p: 3,
                  backgroundColor: theme.palette.background.default,
                  height: '100%', // Ensures consistent height
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 6,
                  },
                }}
              >
                <Typography variant="h6" gutterBottom>
                  {step.title}
                </Typography>
                <Typography 
                   variant="body1" 
                   color="gray.600"
                  >
                  {step.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Key Features Section */}
      <Box
        textAlign="center"
        py={{ xs: 6, md: 10 }}
        sx={{
          width: '100%',
          minHeight: {
            xs: '20vh',    
            sm: '25vh',   
            md: '30vh'
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', 
          alignItems: 'center', 
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Box  py={{xs:1,md:4}}>
          <Typography variant="h4" component="h2" gutterBottom color="textSecondary">
            Key Features
          </Typography>
        </Box>
        <Grid container spacing={6} justifyContent="center">
          {[
            {
              icon: <Build fontSize="large" color="primary" />,
              title: 'AI-Powered Resume Optimization',
              description:
                'Our advanced AI analyzes both your resume and the job description to ensure your content includes the most impactful, job-specific keywords and phrases. This boosts your chances of passing through ATS filters and landing an interview.',
            },
            {
              icon: <Feedback fontSize="large" color="primary" />,
              title: 'Instant Feedback for Immediate Improvement',
              description:
                "Receive real-time feedback on how well your resume aligns with the desired job description. With actionable insights, you'll know exactly where to improve, helping you make adjustments that significantly increase your chances of catching the eye of recruiters.",
            },
            {
              icon: <DesignServices fontSize="large" color="primary" />,
              title: 'Modern, Professional Templates',
              description:
                'Select from a wide variety of professionally designed, ATS-friendly templates that not only look great but are tailored to highlight your skills and experience in the most effective way possible.',
            },
          ].map((feature, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Box
                sx={{
                  maxWidth: 350,
                  mx: 'auto',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                  borderRadius: 2,
                  p: 3,
                  backgroundColor: theme.palette.background.default,
                  height: '100%', // Ensures consistent height
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  '&:hover': {
                    transform: 'translateY(-5px)',
                    boxShadow: 6,
                  },
                }}
              >
                {feature.icon}
                <Typography variant="h5" gutterBottom sx={{ mt: 2 }}>
                  {feature.title}
                </Typography>
                <Typography 
                   variant="body1" 
                   color="gray.600"
                  >
                  {feature.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
        {/* CTA Button */}
        <Box textAlign="center" py={5}>

          { !isWaitlistMode &&
          <Button
            variant="contained"
            size="large"
            onClick={handleGetStartedClick}
            sx={{...theme.button.primary}}
          >
            {isAuthenticated ? "Go to your Dashboard" : "Get Started Now"}
          </Button>
          }
        </Box>
      </Box>

      {/* Resume Analyzer Section */}
      <Box
        textAlign="center"
        py={{ xs: 2, md: 4 }}
        sx={{
          width: '100%',
          minHeight: {
            xs: '20vh',    
            sm: '30vh',   
            md: '40vh',   
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', 
          alignItems: 'center', 
          background: `linear-gradient(0deg, 
            ${theme.palette.background.paper} 0%, 
            ${theme.palette.augmentColor({
              color: theme.palette.secondary,
              mainShade: 'light',
            }).light}80 30%, 
            ${theme.palette.background.paper} 100%)`,
        }}
      >
        <Box  py={{xs:1,md:3}}>
          <Typography variant="h4" component="h2" gutterBottom color="textSecondary">
            Analyze Your Resume Instantly
          </Typography>
        </Box>
        <Typography
          variant="body1"
          color="textPrimary"
          paragraph
          sx={{ maxWidth: 500, mx: 'auto' }}
        >
          Upload your resume for a <b>free analysis</b> and see how it compares to the job descriptions you're targeting. Our AI will instantly evaluate your resume, providing the insights you need to optimize it for specific roles.
        </Typography>

        {/* Placeholder for GIF or Image */}
        <Box
          sx={{
            mt: 4,
            width: '100%',
            minHeight: {
              xs: '20vh',    
              sm: '30vh',   
              md: '40vh',   
            },
            height: 300,
            backgroundColor: theme.palette.grey[200],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.text.secondary,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6">[Placeholder for GIF showing the operation]</Typography>
        </Box>
      </Box>

      {/* Testimonials Section */}
      <Box sx={{ mt: 8 }}>
        <TestimonialsCarousel />
      </Box>

      {/* Informational Box 2 */}
      <InfoBox2 />

      {/* FAQ Section */}
      <Box sx={{ mt: 8 }}>
        <FAQSection />
      </Box>

      {/* Call to Action Section */}
      <Box
        textAlign="center"
        py={{ xs: 6, md: 10 }}
        sx={{
          width: '100%',
          background: `linear-gradient(180deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`,
        }}
      >
        <Typography variant="h4" component="h2" gutterBottom color="textSecondary">
          Start Your Journey to Success
        </Typography>
        <Typography
          variant="body1"
          paragraph
          sx={{ maxWidth: 600, mx: 'auto' }}
        >
          Don't let a poorly written resume hold you back. With ResumeFactory.ai, you'll have the tools you need to create a resume that stands out.
        </Typography>
        { !isWaitlistMode &&
        <Button
          variant="contained"
          size="large"
          onClick={handleGetStartedClick}
          sx={{...theme.button.primary}}
        >
          {isAuthenticated ? "Go to your Dashboard" : "Start Now"}
        </Button>
        }
      </Box>
    </Box>
  );
}