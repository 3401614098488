import {  Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import UserPreferences from "../Components/UserPreferences";



// src/pages/UserPreferencesPage.js
export default function UserPreferencesPage() {

  const navigate = useNavigate();
  const params = useParams();

  // eslint-disable-next-line 
  const backToHome = ()  =>  navigate(`/app/${params.resumeB62Id}`);

  return (
    <Box pt={3}>
      <UserPreferences></UserPreferences>
    </Box>
  );
}
