import React from "react";
import {  FormGroup, LinearProgress, Paper, Switch, Box,  Theme, styled,Typography } from '@mui/material';
import useUser from "../Hooks/useUser";
import { useDispatch } from "react-redux";
import { onAlertShowAction } from "../Redux";
import { httpOnGetUser, httpOnPutUserPreferences } from "../Services/Http";
import { IUserPreferences } from "../Services/Http/interface";

const debugLogs = process.env.REACT_APP_DEBUG_LOGS;

const PREFIX = 'UserPreferencesModal';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  box: `${PREFIX}-box`,
  dragNdrop: `${PREFIX}-dragNdrop`,
  modalRoot: `${PREFIX}-modalRoot`,
  textInput: `${PREFIX}-textInput`,
  iconContainer: `${PREFIX}-iconContainer`,
  error: `${PREFIX}-error`,
  button: `${PREFIX}-button`,
  browseFilesButton: `${PREFIX}-browseFilesButton`,
  title: `${PREFIX}-title`
};


const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    
  },

  [`& .${classes.container}`]: {
    position: "absolute",
    borderRadius: 7,
    width: "50%",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    margin: theme.spacing(2),
    backgroundColor: theme.colors.white,

  },

  [`& .${classes.box}`]: {
    alignItems: "center",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },

  [`& .${classes.dragNdrop}`]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(3),
    borderRadius: 7,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: theme.palette.secondary.main,

  },

  [`& .${classes.modalRoot}`]: {
    width: "100%",
  },

  [`& .${classes.textInput}`]: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    textAlign: "center",
    marginTop: theme.spacing(3),
  },

  [`& .${classes.iconContainer}`]: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },

  [`& .${classes.error}`]: {
    color: theme.colors.error,
    width: '100%',
    justifyContent: 'flex-start',
    display: 'flex',
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(3)
  },

  [`& .${classes.browseFilesButton}`]: {
    marginTop: theme.spacing(2)
  },

  [`& .${classes.title}`]: {
    fontSize: theme.fontSize.lg,
    fontWeight: 'bold',
    color: theme.palette.primary.main
  }
}));

interface Props {
  onClose?: () => void;
}


const UserPreferences: React.FC<Props> = ({ onClose }) => {
  const { userId } = useUser();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [saveInProgress, setSaveInProgress] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>("");
  const [userPreferencesData, setUserPreferencesData] = React.useState<IUserPreferences>({});


  const dispatch = useDispatch();


  React.useEffect(() => {

    async function fetchData() {
      setIsLoading(true);

      try {
        debugLogs && console.log('httpOnGetUser')
        const res: any = await httpOnGetUser(userId)

        setUserPreferencesData(res?.user_preferences);

        setIsLoading(false);

      } catch (e) {
        displayError('Sorry, something went wrong while fetching User Preferences')
        setIsLoading(false);
        return;
      }

      setErrorMessage('');
    }

    fetchData();
  // eslint-disable-next-line 
  }, []);


  const handleSave = async (userPreferencesData: any) => {
    setSaveInProgress(true);

    setUserPreferencesData(userPreferencesData);

    try {
      debugLogs && console.log('httpOnPutUserPreferences')
      // eslint-disable-next-line 
      const res: any = await httpOnPutUserPreferences(userId, userPreferencesData)
      setSaveInProgress(false);
    } catch (e) {
      displayError('Sorry, something went wrong while saving the changes.')
      setSaveInProgress(false);
      return;
    }

  }


  const displayError = (errorMessage: string) => {
    dispatch(onAlertShowAction({ type: 'error', message: errorMessage }));
    setErrorMessage(errorMessage);
  }

  return (
      <Root
        className={classes.root}>
        <Box>
          <Paper>
            { (isLoading || saveInProgress) && <Box>
              <LinearProgress sx={{marginTop: '-4px'}}/>
            </Box>}

            <Box display={'flex'} flexDirection='column' pt={2}>
              <Box pl={1} pb={1}>
                <Typography variant="h6" gutterBottom>
                  User Preferences
                </Typography>
              </Box>
            
              <Box pl={1} pb={1}>
                <FormGroup row style={{justifyContent:  "start", width:  "100%"}}>
                <Typography variant="subtitle2" gutterBottom sx={{width: 180}}>
                  Exclude Education Dates
                </Typography>
                <Switch sx={{marginTop: '-8px'}} disabled={isLoading}
                  checked={userPreferencesData?.exclude_education_dates} onChange={(e) => handleSave({...userPreferencesData, exclude_education_dates: e.target.checked})} />
                </FormGroup>
              </Box>

              <Box pl={1} pb={1}>
              <FormGroup row style={{justifyContent: "start", width: "100%"}}>
                <Typography variant="subtitle2" gutterBottom sx={{width: 180}}>
                  Show Objective Statement
                </Typography>
                <Switch sx={{marginTop: '-8px'}} disabled={isLoading}
                  checked={userPreferencesData?.show_objective_statement} onChange={(e) => handleSave({...userPreferencesData, show_objective_statement: e.target.checked})} />
                </FormGroup>
              </Box>
                
              <Box pl={1} pb={1}>
              <FormGroup row style={{justifyContent: "start", width: "100%"}}>
                <Typography variant="subtitle2" gutterBottom sx={{width: 180}}>
                  Show Experience Decks
                </Typography>
                <Switch sx={{marginTop: '-8px'}} disabled={isLoading}
                  checked={userPreferencesData?.show_experience_deks} onChange={(e) => handleSave({...userPreferencesData, show_experience_deks: e.target.checked})} />
              </FormGroup>
              </Box>
                
              <Box pl={1} pb={1}>
              <FormGroup row style={{justifyContent: "start", width: "100%"}}>
                <Typography variant="subtitle2" gutterBottom sx={{width: 180}}>
                  Use Skills First Layout
                </Typography>
                <Switch sx={{marginTop: '-8px'}} disabled={isLoading}
                  checked={userPreferencesData?.use_skills_first_layout} onChange={(e) => handleSave({...userPreferencesData, use_skills_first_layout: e.target.checked})} />
              </FormGroup>
              </Box>

            </Box>

            { errorMessage &&
              <Box pb={2} pt={1}>
                <Typography color={'error'}>{errorMessage}</Typography>
              </Box>
            }


          </Paper>
        </Box>

      </Root>
  );
};

export default UserPreferences;
