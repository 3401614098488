import React, { useState } from "react";
import {  Box, IconButton, Typography, Theme,styled } from "@mui/material";

import {
  DeleteForever,
  Add as IconAdd,
  Undo,
} from '@mui/icons-material';
import PrimaryTextInput from "../PrimaryTextInput";
import { Controller, useFieldArray, } from "react-hook-form";
import { borderedSectionStyles, formDiffRedColor } from "./styles";
import theme from "../../theme";


const PREFIX = 'ResumeForm';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`
};


const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
  
    display: "flex",
    flexDirection: 'column',
    alignItems: "left",
    justifyContent: "left",
  },

}));



interface ResumeFormAwardProps {
  control: any,
  _required?: boolean,
  diffOriginData?: any;
  showDiff: boolean;
}


const ResumeFormAward: React.FC<ResumeFormAwardProps> = ({ control, _required, diffOriginData, showDiff }) => {
  // eslint-disable-next-line 
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'awards', // unique name for your Field Array
  });

  const awards = control._formValues?.awards;

  const [state, setState] = useState<number>(0); // solely to trigger re-render for to have updated awards variable in the component

  return <Root className={classes.root}>
    <Typography>Awards
      <IconButton onClick={() => {append({})}}
        disabled={awards?.length > 0 && !(awards[awards.length -1]?.title || awards[awards.length -1]?.date)}>
        <IconAdd/>
      </IconButton>
    </Typography>

    {fields.map((field: any, index: number) => (
      <Box key={field.id} sx={{display: 'flex'}}>
        <Box sx={{flexGrow: 1, ...borderedSectionStyles}}>

          <Controller
            name={`awards.${index}.title`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Box>
                <PrimaryTextInput
                  name="title"
                  label="Title"
                  onChange={(event: any) => {onChange(event); setState(state+1)}}
                  value={value || ''}
                  required={_required && true}
                  multiline={true}
                />
              </Box>
            )}
          />

          <Controller
            name={`awards.${index}.date`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Box>
                <PrimaryTextInput
                  name="Date"
                  label="date"
                  onChange={(event: any) => {onChange(event); setState(state+1)}}
                  value={value || ''}
                  required={_required && true}
                  multiline={true}
                />
              </Box>
            )}
          />


        </Box>

        <Box pt={'13px'}>
          <IconButton onClick={() => {remove(index)}}>
            <DeleteForever/>
          </IconButton>
        </Box>

      </Box>
    ))}


    {/* Removed awards */}
    { showDiff && (diffOriginData?.awards?.length > fields?.length) &&
      [...(diffOriginData?.awards || [])].slice(fields?.length).map((field: any, index: number) => (

      <Box key={field.id} sx={{display: 'flex'}}>

        <Box sx={{flexGrow: 1, ...borderedSectionStyles,
          position: 'relative',
          border: `6px dashed ${formDiffRedColor}`
        }}>

          <Box>
            <Typography variant="body2" sx={{background: formDiffRedColor, p:1, borderRadius: theme.spacing(0.5)}}>
              Deleted Award{field?.title ? ':' : ''} {field?.title}
            </Typography>
          </Box>

          <Box>
            <PrimaryTextInput
              name="title"
              label="Title"
              value={ field?.title || '' }
              multiline={true}
              disabled={true}
            />
          </Box>

          <Box>
            <PrimaryTextInput
              name="Date"
              label="date"
              value={ field?.date || '' }
              multiline={true}
              disabled={true}
            />
          </Box>

        </Box>

        <Box pt={'13px'}>
          <IconButton onClick={() => {append(field)}}>
            <Undo/>
          </IconButton>
        </Box>

      </Box>
    ))}




  </Root>
}



export default ResumeFormAward;