// src/components/Layout.js
import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import ResponsiveAppBar from './AppBar';
import { Box, Container, Typography, Link as MuiLink, Breakpoint } from '@mui/material';
import theme from '../theme';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {"  © "}
      <MuiLink color="inherit" href="https://ResumeFactory.ai/">
        ResumeFactory.ai
      </MuiLink>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const Layout: React.FC<{ user: any, containerDisableGutters?: boolean, containerMaxWidth?: Breakpoint|boolean }> = ({ user, containerDisableGutters, containerMaxWidth }) => {


  containerDisableGutters = containerDisableGutters || false;
  if (!containerMaxWidth && containerMaxWidth !== false) {
    containerMaxWidth = containerMaxWidth || 'xl';
  }

  return (
    <Box 
        sx={{ minHeight: '100vh', 
              width: '100%',
              display: "flex", 
              flexDirection: "column",
              //backgroundColor: theme.palette.common.white
              //background: `linear-gradient(135deg, ${theme.palette.primary.light} 30%, ${theme.palette.secondary.light} 90%)`,
              
              }}>
      <ResponsiveAppBar user={user} containerMaxWidth={containerMaxWidth} containerDisableGutters={containerDisableGutters}/>

      {/* @ts-ignore */}
      <Container component="main" maxWidth={containerMaxWidth} disableGutters={containerDisableGutters}>
        <Outlet />
      </Container>
      
      <Box
        component="footer"
        sx={{
          marginTop: 'auto',
          py: 3,
          px: 2,
          backgroundColor: theme.palette.grey[200],
        }}
      >
        {/* @ts-ignore */}
        <Container maxWidth={containerMaxWidth} disableGutters={containerDisableGutters}>
          <Typography variant="body1">
            {/* Add any additional footer content here */}
          </Typography>
          <Box mt={2} display="flex" justifyContent="center" alignItems="center" flexWrap='wrap' gap={2}>
            <MuiLink component={Link} to="/privacy" variant="body2">
              Privacy Policy
            </MuiLink>
            <MuiLink component={Link} to="/terms" variant="body2">
              Terms of Service
            </MuiLink>
            <Copyright />
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Layout;