// src/components/FAQSection.tsx

import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface FAQItem {
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    question: "How does ResumeFactory.ai work?",
    answer:
      "Our AI analyzes your current resume and the job description you're targeting to provide optimized content, keyword integration, and formatting suggestions.",
  },
  {
    question: "Is my data secure?",
    answer:
      "Yes, we prioritize your privacy and ensure that all your data is securely stored and never shared with third parties.",
  },
  {
    question: "Can I customize my resume templates?",
    answer:
      "Absolutely! We offer a variety of professional templates that you can fully customize to match your personal style and the job you're applying for.",
  },
  // Add more FAQs as needed
];

export default function FAQSection() {
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Box
      textAlign="center"
      py={{ xs: 6, md: 10 }}
      sx={{
        background: `linear-gradient(0deg, ${theme.palette.background.default} 0%, ${theme.palette.background.paper} 100%)`, // Subtle gradient with light gray tones
      }}
    >
      <Typography variant="h4" component="h2" gutterBottom color="textSecondary">
        Frequently Asked Questions
      </Typography>
      <Box maxWidth="md" mx="auto" textAlign="left">
        {faqData.map((item, index) => {
          const panelId = `panel${index}`;
          const isExpanded = expanded === panelId;
          return (
            <Accordion
              key={panelId}
              expanded={isExpanded}
              onChange={handleChange(panelId)}
              disableGutters
              square
              sx={{
                mb: 2,
                boxShadow: 'none',
                borderBottom: `1px solid ${theme.palette.divider}`,
                '&:before': {
                  display: 'none',
                },
                '& .MuiAccordionSummary-root': {
                  paddingLeft: 0,
                  paddingRight: 0,
                },
                '& .MuiAccordionDetails-root': {
                  paddingLeft: 0,
                  paddingRight: 0,
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${panelId}-content`}
                id={`${panelId}-header`}
                sx={{
                  flexDirection: 'row-reverse',
                  '& .MuiAccordionSummary-content': {
                    marginLeft: theme.spacing(1),
                  },
                  backgroundColor: isExpanded
                    ? theme.palette.action.hover
                    : 'inherit',
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                  {item.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  variant="body1"
                  color="text.secondary"
                  sx={{ 
                    lineHeight: 1.6 ,
                    backgroundColor: theme.palette.background.default,
                  }}
                >
                  {item.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
}
