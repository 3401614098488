import React from 'react';
import { Typography, Box} from '@mui/material';

const PrivacyPolicy: React.FC = () => {
  return (
    <Box padding={3}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Effective Date: August 19, 2024
      </Typography>
      <Typography variant="body1" paragraph>
        ResumeFactoryAI is a generative AI tool offered by ObexMetrics Inc. ObexMetrics Inc ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Service. Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access or use the Service.
      </Typography>
      <Typography variant="h6" gutterBottom>
        1. Information We Collect
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Personal Information:</strong> When you use our Service, we may collect personal information such as your name, email address, and payment information. We may also collect any personal information included in the resumes and job descriptions you submit.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Usage Data:</strong> We may collect information automatically when you use the Service, such as your IP address, browser type, device information, operating system, and usage patterns. This data helps us understand how you interact with the Service and allows us to improve its functionality.
      </Typography>
      <Typography variant="h6" gutterBottom>
        2. How We Use Your Information
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Service Delivery:</strong> We use the information you provide to deliver and customize the resume rewriting service. This includes processing the resumes and job descriptions you submit to generate customized resumes tailored to your specifications.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Improving the Service:</strong> We may use aggregated and anonymized data to analyze trends, monitor the effectiveness of our Service, and improve its functionality.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Communication:</strong> We may use your contact information to send you updates, notifications, and other communications related to the Service. You can opt-out of non-essential communications at any time.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Legal Compliance:</strong> We may use your information as required by applicable laws and regulations, including to comply with legal processes or enforce our Terms of Service.
      </Typography>
      <Typography variant="h6" gutterBottom>
        3. Sharing Your Information
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Third-Party Service Providers:</strong> We may share your information with third-party service providers, such as payment processors and cloud service providers, who assist us in operating the Service. These providers are required to protect your information and use it only for the purposes for which it was provided.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Generative AI Services:</strong> To provide the resume rewriting service, we may transmit the content you submit (including resumes and job descriptions) to third-party providers, such as OpenAI and Anthropic, for processing by their generative AI models. These third-party services are used solely to deliver the Service and operate under their own privacy policies.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities, such as a court or government agency.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Business Transfers:</strong> If we are involved in a merger, acquisition, or asset sale, your information may be transferred as part of that transaction. We will provide notice before your information is transferred and becomes subject to a different privacy policy.
      </Typography>
      <Typography variant="h6" gutterBottom>
        4. Security of Your Information
      </Typography>
      <Typography variant="body1" paragraph>
        We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
      </Typography>
      <Typography variant="h6" gutterBottom>
        5. Data Retention
      </Typography>
      <Typography variant="body1" paragraph>
        We will retain your personal information only for as long as necessary to fulfill the purposes for which it was collected, including to comply with legal obligations, resolve disputes, and enforce our agreements.
      </Typography>
      <Typography variant="h6" gutterBottom>
        6. Your Rights and Choices
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Access and Correction:</strong> You have the right to access the personal information we hold about you and to request corrections or updates. You may also request the deletion of your personal information, subject to certain legal exceptions.
      </Typography>
      <Typography variant="body2" paragraph>
        <strong>Opt-Out:</strong> You may opt-out of receiving non-essential communications from us by following the unsubscribe instructions provided in the emails. Please note that you may still receive administrative and service-related communications.
      </Typography>
      <Typography variant="h6" gutterBottom>
        7. International Data Transfers
      </Typography>
      <Typography variant="body1" paragraph>
        If you are accessing our Service from outside the United States, please be aware that your information may be transferred to, stored, and processed in the United States or other countries where our service providers are located. By using the Service, you consent to such transfers.
      </Typography>
      <Typography variant="h6" gutterBottom>
        8. Children's Privacy
      </Typography>
      <Typography variant="body1" paragraph>
        Our Service is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have inadvertently collected personal information from a child under 18, we will take steps to delete such information.
      </Typography>
      <Typography variant="h6" gutterBottom>
        9. Changes to This Privacy Policy
      </Typography>
      <Typography variant="body1" paragraph>
        We may update this Privacy Policy from time to time. Any changes will be effective immediately upon posting the updated Privacy Policy on the Service. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.
      </Typography>
      <Typography variant="h6" gutterBottom>
        10. Contact Us
      </Typography>
      <Typography variant="body1" paragraph>
        If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
      </Typography>
      <Typography variant="body2" paragraph>
        Email: contact@obexmetrics.com
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
