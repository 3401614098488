import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, TextField, Alert, LinearProgress } from '@mui/material';
import Button from '../Components/Button';
import { useSelector } from 'react-redux';
import { selectUser } from '../Redux'; 
import { useNavigate } from 'react-router-dom';
import { awsOnChangePassword } from '../Services/Aws/User'
import ConfirmDialogBox from '../Components/ConfirmDialogBox';
import { httpOnPurgeAllMyData } from '../Services/Http';

const PREFIX = 'Profile';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  header: `${PREFIX}-header`,
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  input: `${PREFIX}-input`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(3),
    minHeight: '100vh',
  },

  [`& .${classes.container}`]: {
    maxWidth: 600,
    margin: '0 auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[2],
  },

  [`& .${classes.header}`]: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  [`& .${classes.content}`]: {
    marginBottom: theme.spacing(3),
  },

  [`& .${classes.button}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.input}`]: {
    marginBottom: theme.spacing(2),
  },
}));

const Profile: React.FC = () => {
  const user = useSelector(selectUser); 
  const navigate = useNavigate();

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [changePasswordInProgress, setChangePasswordInProgress] = useState(false);

  const [purgeAllMyDataDialogOpen, setPurgeAllMyDataDialogOpen] = useState<boolean>(false)
  const [purgeAllMyDataError, setPurgeAllMyDataError] = useState('');
  const [purgeAllMyDataSuccess, setPurgeAllMyDataSuccess] = useState('');
  const [purgeAllMyDataInProgress, setPurgeAllMyDataInProgress] = useState<boolean>(false);

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setError('New passwords do not match');
      return;
    }

    setChangePasswordInProgress(true);

    try {
      await awsOnChangePassword(user.email, oldPassword, newPassword);
      setSuccess('Password changed successfully!');
      setError('');
      setTimeout(() => {
        navigate('/app');
      }, 1000);
    } catch (err) {
      setError('Failed to change password. Please try again.');
      setSuccess('');
    } finally {
      setChangePasswordInProgress(false);
     }
  }

  const onConfirmPurgeAllMyData = async () => {
    setPurgeAllMyDataInProgress(true);
    setPurgeAllMyDataDialogOpen(false);
    setPurgeAllMyDataError('');
    setPurgeAllMyDataSuccess('');

    try {
      await httpOnPurgeAllMyData(user.userId);
      setPurgeAllMyDataSuccess('Your data purged successfully! Navigating back to home.');
      setTimeout(() => {
        navigate('/app');
      }, 2000);
    } catch (err) {
      setPurgeAllMyDataSuccess('Failed to purge the data. Please try again.');
      setPurgeAllMyDataError('');
    } finally {
      setPurgeAllMyDataInProgress(false);
    }

  }

  return (
    <Root className={classes.root}>
      <Box className={classes.container}>
        { changePasswordInProgress && <LinearProgress
            sx={{ flexGrow: 1,
              borderTopRightRadius: '4px',
              borderTopLeftRadius: '4px'
            }}
          />}
        <Box p={2}>
          <div className={classes.header}>
            <Typography variant="h6">Profile</Typography>
          </div>
          <Typography variant="body1" className={classes.content}>
            Change your password here.
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          {success && <Alert severity="success">{success}</Alert>}
          <TextField
            label="Old Password"
            type="password"
            variant="outlined"
            fullWidth
            className={classes.input}
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
          <TextField
            label="New Password"
            type="password"
            variant="outlined"
            fullWidth
            className={classes.input}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            label="Confirm New Password"
            type="password"
            variant="outlined"
            fullWidth
            className={classes.input}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <Button
            label="Change Password"
            className={classes.button}
            onClick={handleChangePassword}
            disabled={changePasswordInProgress}
          />
        </Box>
      </Box>

      <Box pt={2}></Box>


      <Box className={classes.container}>
        { purgeAllMyDataInProgress && <LinearProgress 
          sx={{ flexGrow: 1,
            borderTopRightRadius: '4px',
            borderTopLeftRadius: '4px'
          }}
        />}
        <Box p={2}>
          <div className={classes.header}>
            <Typography variant="h6">Purge profile data</Typography>
          </div>
          <Typography variant="body1" className={classes.content}>
            This will purge all your application data and files, returning you to a clean slate.
            It will not delete your account or settings. This process is irreversible!
          </Typography>
          {purgeAllMyDataError && <Alert severity="error">{purgeAllMyDataError}</Alert>}
          {purgeAllMyDataSuccess && <Alert severity="success">{purgeAllMyDataSuccess}</Alert>}
          <Button
            label="Purge all my data"
            className={classes.button}
            disabled={purgeAllMyDataInProgress}
            onClick={() => setPurgeAllMyDataDialogOpen(true)}
          />
        </Box>
      </Box>

      <ConfirmDialogBox
        title="Please confirm"
        message="This will purge all your application data and files, returning you to a clean slate.
        It will not delete your account or settings. This process is irreversible!
        Please confirm you want to proceed?"
        open={purgeAllMyDataDialogOpen}
        primaryButton={{ label: 'Purge All My Data', onClick: onConfirmPurgeAllMyData }}
        secondaryButton={{ label: 'Cancel', onClick: () => setPurgeAllMyDataDialogOpen(false) }}
        fullWidth
        onClose={() => setPurgeAllMyDataDialogOpen(false)}
      />
    </Root>
  );
};

export default Profile;
