import React from "react";
import {  Box,  Typography, Theme, styled  } from "@mui/material";
import PrimaryTextInput from "../PrimaryTextInput";
import { Controller} from "react-hook-form";

const PREFIX = 'ResumeForm';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`
};


const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
  
    display: "flex",
    flexDirection: 'column',
    alignItems: "left",
    justifyContent: "left",
  },

}));


interface ResumeFormContactProps {
  control: any,
  diffOriginData?: any;
}

const ResumeFormContact: React.FC<ResumeFormContactProps> = ({ control, diffOriginData }) => {
  return <Root className={classes.root}>
    <Typography>Contact Details</Typography>
  
    <Box sx={{display: 'flex', flexDirection: {xs: 'column', sm: 'column', md: 'row'}, justifyContent:'space-between'}}>
      <Box sx={{flexGrow: 1}}>
        <Controller
          name="contact.email"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Box>
              <PrimaryTextInput
                name="email"
                label="Email"
                onChange={onChange}
                value={value || ''}
                required={true}
                type="email"
                multiline={true}
              />
            </Box>
          )}
        />

        <Controller
          name="contact.phone"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Box>
              <PrimaryTextInput
                name="phone"
                label="Phone"
                onChange={onChange}
                value={value || ''}
                required={false}
                type="phone"
                multiline={true}
              />
            </Box>
          )}
        />

        <Controller
          name="contact.website"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Box>
              <PrimaryTextInput
                name="website"
                label="Website"
                onChange={onChange}
                value={value || ''}
                required={false}
                multiline={true}
              />
            </Box>
          )}
        />

      </Box>

      <Box sx={{flexGrow: 0.1}}></Box>

      <Box sx={{flexGrow: 1}}>

        <Controller
          name="contact.linkedin"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Box>
              <PrimaryTextInput
                name="linkedin"
                label="Linkedin"
                onChange={onChange}
                value={value || ''}
                required={false}
                multiline={true}
              />
            </Box>
          )}
        />

        <Controller
          name="contact.github"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Box>
              <PrimaryTextInput
                name="github"
                label="Github"
                onChange={onChange}
                value={value || ''}
                required={false}
                multiline={true}
              />
            </Box>
          )}
        />

      </Box>

    </Box>
  </Root>
}



export default ResumeFormContact;