import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { AccountCircle } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { onSignOutAction } from '../Redux'; 
import theme from '../theme'
import ImgLogo from '../Assets/logo-net.png';
import { Breakpoint } from '@mui/material';

const isWaitlistMode = !!process.env.REACT_APP_IS_WAITLIST_MODE;

interface AppBarProps {
  user: { name: string } | null;
  containerDisableGutters?: boolean;
  containerMaxWidth?: Breakpoint|boolean;
}

function ResponsiveAppBar({ user, containerMaxWidth, containerDisableGutters }: AppBarProps) {
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNavigate = (path: string) => {
    navigate(path);
    handleCloseUserMenu();
  };

  const handleLogOut = () => {
    dispatch(onSignOutAction()); // Clear user data from Redux
    navigate('/'); // Navigate to the sign-in page or home page
    handleCloseUserMenu();
  };

  return (
    <AppBar position="static">
      {/* @ts-ignore */}
      <Container maxWidth={containerMaxWidth} disableGutters={containerDisableGutters}
        sx={ containerDisableGutters ? { paddingLeft: {md: 3, xs: 2, xl: 0}, paddingRight: {md: 3, xs: 2, xl: 0} } : {} }
        >
        <Toolbar disableGutters sx={{flexWrap: 'wrap' }}>
          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box
                component="img"
                src={ImgLogo}
                alt="Logo"
                onClick={() => navigate('/app')}
                sx={{
                  maxWidth: 50,
                  marginRight: 2,
                  cursor: 'pointer',
                }}
            ></Box>
            <Typography
              variant="h6"
              noWrap
              component="a"
              onClick={() => navigate('/app')}
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                fontFamily: theme.typography.fontFamily,
                fontWeight: theme.typography.fontWeightBold,
                color: 'inherit',
                textDecoration: 'none',
                cursor: 'pointer'
              }}
            >
              ResumeFactory.ai
            </Typography>

            <Typography
              variant="h5"
              noWrap
              component="a"
              onClick={() => navigate('/app')}
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                flexGrow: 1,
                fontSize: '1.13rem',
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.15rem',
                color: 'inherit',
                textDecoration: 'none',
                cursor: 'pointer'
              }}
            >
              ResumeFactory.ai
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexGrow: 1, justifyContent: 'end', flexWrap: 'wrap' }}>

            {user && user.name ? (
              <>
              <Typography variant="h6" sx={{ marginRight: 2, display: { xs: 'none', sm: 'flex' } }}>
                Hi {user.name}
              </Typography>

              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: 2, color: 'white' }}>
                  <AccountCircle />
                </IconButton>
              </Tooltip>

              <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                >
                <MenuItem onClick={() => handleNavigate('/app/profile') }>
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>

                <MenuItem onClick={() => handleNavigate('/app/user-Preferences') }>
                  <Typography textAlign="center">User Preferences</Typography>
                </MenuItem>

                <MenuItem onClick={handleLogOut}>
                  <Typography textAlign="center">Log Out</Typography>
                </MenuItem>

              </Menu>
              </>
            ) : (
              !isWaitlistMode ?
              <>
                <Button
                  onClick={() => handleNavigate('/login')}
                  variant="outlined"
                  sx={{
                    color: theme.button.secondary.color,
                    borderColor: theme.button.secondary.backgroundColor,
                    '&:hover': {
                      borderColor: theme.palette.common.white,
                      backgroundColor: theme.button.primary['&:hover']?.backgroundColor,
                    },
                    marginRight: 2,
                  }}
                >
                  Log In
                </Button>
                <Button
                  onClick={() => handleNavigate('/signup')}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.button.primary.backgroundColor,
                    color: theme.button.primary.color,
                    '&:hover': {
                      borderColor: theme.button.secondary['&:hover']?.backgroundColor,
                      backgroundColor: theme.button.primary['&:hover']?.backgroundColor,
                    },
                  }}
                >
                  Sign Up
                </Button>
              </>: null
            )}
          </Box>
          {/* 
          {user && user.name && (
              <Box sx={{ display: { xs: 'flex', sm: 'none', md: 'none' }, justifyContent: 'center', width: '100%' }}>
                <Typography variant="h6">
                  Hi {user.name}
                </Typography>
              </Box>
          )} */}



        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
