import React, { useState } from "react";
import { Box, IconButton, Typography, Theme, TextField, styled } from "@mui/material";
import {
  DeleteForever,
  Add as IconAdd,
  Undo,
} from '@mui/icons-material';
import PrimaryTextInput from "../PrimaryTextInput";
import { Controller, useFieldArray} from "react-hook-form";
import TextDiffComponent from "./TextDiffComponent";
import DateOrDates from "./DateOrDates";
import theme from "../../theme";
import { borderedSectionStyles, formDiffRedColor } from "./styles";
import { FormRemovedDetail } from "./FormRemovedDetail";

const PREFIX = 'ResumeForm';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`
};


const Root = styled(Box)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(1),
  
    display: "flex",
    flexDirection: 'column',
    alignItems: "left",
    justifyContent: "left",
  },

}));


// "experience": {
//   "description": "Candidate education credentials",
//   "items": {
//       "$ref": "#/$defs/Experience"
//   },
//   "title": "Experience",
//   "type": "array"
// },

// "Experience": {
//   "properties": {
//       "company": {
//           "title": "Company",
//           "type": "string"
//       },
//       "location": {
//           "title": "Location",
//           "type": "string"
//       },
//       "title": {
//           "title": "Title",
//           "type": "string"
//       },
//       "dates": {
//           "title": "Dates",
//           "type": "string"
//       },
//       "details": {
//           "items": {
//               "type": "string"
//           },
//           "title": "Details",
//           "type": "array"
//       },
//       "dek": {
//           "anyOf": [
//               {
//                   "type": "string"
//               },
//               {
//                   "type": "null"
//               }
//           ],
//           "default": null,
//           "title": "Dek"
//       }
//   },
//   "required": [
//       "company",
//       "location",
//       "title",
//       "dates",
//       "details"
//   ],
//   "title": "Experience",
//   "type": "object"
// }

// {
//   "dek": null,
//   "dates": "Oct 2016 – Present",
//   "title": "Software Engineer",
//   "company": "Google",
//   "details": [
//     "TensorFlow: Worked on APIs and performance for training models on Tensor Processing Units (TPU).",
//     "Apache Beam: Worked on batch and streaming data-parallel processing pipelines."
//   ],
//   "location": "Mountain View, CA"
// },

interface ResumeFormExperienceProps {
  control: any,
  _required?: boolean,
  diffOriginData?: any,
  showDiff: boolean
}


const ResumeFormExperience: React.FC<ResumeFormExperienceProps> = ({ control, _required, diffOriginData, showDiff }) => {
  // eslint-disable-next-line
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormProvider)
    name: 'experience', // unique name for your Field Array
  });

  const experience = control._formValues?.experience;

  const [state, setState] = useState<number>(0); // solely to trigger re-render for to have updated awards variable in the component

  return <Root className={classes.root}>
    <Typography>Experience
      <IconButton onClick={() => {append({})}}
        disabled={experience?.length > 0 && !(experience[experience.length -1]?.title || experience[experience.length -1]?.company)}>
        <IconAdd/>
      </IconButton>
    </Typography>

    {fields.map((field: any, index: number) => (
      <Box key={field.id} sx={{display: 'flex'}}>

        <Box sx={{flexGrow: 1, ...borderedSectionStyles }}>

          <Controller
            name={`experience.${index}.title`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Box>
                <PrimaryTextInput
                  name="title"
                  label="Title"
                  onChange={(event:any) => {onChange(event); setState(state + 1)}}
                  value={ value || '' }
                  defaultValue={ diffOriginData?.experience?.[index]?.title || '' }
                  required={_required && true}
                  multiline={true}
                />

              </Box>
            )}
          />

          <Controller
            name={`experience.${index}.company`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Box>
                <PrimaryTextInput
                  name="company"
                  label="Company"
                  onChange={(event:any) => {onChange(event); setState(state + 1)}}
                  value={ value || '' }
                  defaultValue={ diffOriginData?.experience?.[index]?.company || ''}
                  required={_required && true}
                  multiline={true}
                />

              </Box>
            )}
          />

          <Controller
            name={`experience.${index}.location`}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Box>
                <PrimaryTextInput
                  name="location"
                  label="Location"
                  onChange={onChange}
                  value={ value || '' }
                  defaultValue={ diffOriginData?.experience?.[index]?.location || '' }
                  required={_required && true}
                  multiline={true}
                />

              </Box>
            )}
          />

          <DateOrDates
              formPath={`experience.${index}.date_or_dates`}
              // dateIsRange={control._formValues?.experience?.[index]?.date_is_range}
              dateIsRange={true}
              control={control}
              required={_required && true}
          />

          <Controller
            name={`experience.${index}.details`}
            control={control}
            defaultValue={[]}
            render={({ field: { onChange: onChangeDetails, onBlur, value: values } }) => (
              <>

                <Typography>Experience Details
                  <IconButton onClick={() => {onChangeDetails([...values, ''])}}
                    disabled={values.length > 0 && values[values.length - 1] === ''}
                    >
                    <IconAdd/>
                  </IconButton>
                </Typography>

                {values.map((val: any, i: number) => (
                      <Box>
                        <Box sx={{display: 'flex' }}>

                          <Box sx={{flexGrow: '1'}}>
                            <TextField
                              value={val}
                              onChange={(newVal) => onChangeDetails([...(values[i] = newVal.target.value, values)])}
                              size="small"
                              style={{ width: '100%' }}
                              multiline={true}
                              sx={{mb: theme.spacing(0.5) }}
                            />

                            { showDiff && <TextDiffComponent originalText={diffOriginData?.experience?.[index].details?.[i] || ''}
                                                                        updatedText={val || ''}
                                                                        onTextUpdated={(newVal) => onChangeDetails([...(values[i] = newVal, values)])}
                                                    /> }

                          </Box>

                          <Box>
                            <IconButton onClick={() => {onChangeDetails([...(values.splice(i, 1), values)])}}>
                              <DeleteForever/>
                            </IconButton>
                          </Box>

                        </Box>


                      </Box>
                ))}

                {diffOriginData?.experience?.[index]?.details?.slice(values?.length || 0).map((val: any, i: number) => (
                  
                  <FormRemovedDetail val={val} onChangeDetails={onChangeDetails} values={values} />

                ))}

              </>
            )}
          />
        </Box>

        <Box pt={'13px'}>
          <IconButton onClick={() => {remove(index)}}>
            <DeleteForever/>
          </IconButton>
        </Box>

      </Box>
    ))}

    {fields.length > 0 && <Typography variant="body1" sx={{fontSize: '12px'}}>Add More Experience
      <IconButton onClick={() => {append({})}}
        disabled={experience?.length > 0 && !(experience[experience.length -1]?.title || experience[experience.length -1]?.company)}>
        <IconAdd/>
      </IconButton>
    </Typography>}




    {/* Removed experiences */}
    { showDiff && (diffOriginData?.experience?.length > fields?.length) &&
      [...(diffOriginData?.experience || [])].slice(fields?.length).map((field: any, index: number) => (

      <Box key={field.id} sx={{display: 'flex'}}>

        <Box sx={{flexGrow: 1, ...borderedSectionStyles,
          position: 'relative',
          border: `6px dashed ${formDiffRedColor}`
        }}>

          <Box>
            <Typography variant="body2" sx={{background: formDiffRedColor, p:1, borderRadius: theme.spacing(0.5)}}>
              Deleted Experience{field?.title ? ':' : ''} {field?.title}
            </Typography>
          </Box>

          <Box>
            <PrimaryTextInput
              name="title"
              label="Title"
              value={ field?.title || '' }
              multiline={true}
              disabled={true}
            />
          </Box>

          <Box>
            <PrimaryTextInput
              name="company"
              label="Company"
              value={ field?.company || '' }
              multiline={true}
              disabled={true}
            />
          </Box>

          <Box>
            <PrimaryTextInput
              name="location"
              label="Location"
              value={ field?.location || '' }
              multiline={true}
              disabled={true}
            />
          </Box>

          <Box>
            <PrimaryTextInput
              name="date"
              label="Date info"
              value={ field?.rendered_dates || '' }
              multiline={true}
              disabled={true}
            />
          </Box>

            <>

              <Typography>Experience Details</Typography>

              {field?.details?.map((val: any, i: number) => (
                    <Box>
                      <Box sx={{display: 'flex' }}>

                        <Box sx={{flexGrow: '1'}}>
                          <TextField
                            value={val}
                            size="small"
                            style={{ width: '100%' }}
                            multiline={true}
                            sx={{mb: theme.spacing(0.5) }}
                            disabled={true}
                          />

                        </Box>

                      </Box>


                    </Box>
              ))}

            </>
        </Box>

        <Box pt={'13px'}>
          <IconButton onClick={() => {append(field)}}>
            <Undo/>
          </IconButton>
        </Box>

      </Box>
    ))}



  </Root>
}



export default ResumeFormExperience;
