import React from 'react';
import { Box } from '@mui/material';
import PrimaryTextInput from '../PrimaryTextInput';
import { Controller } from 'react-hook-form';

interface Props {
  dateIsRange: boolean;
  required?: boolean;
  formPath: string; // e.g. `awards.${index}.date_or_dates`
  control: any;
    // ...props;
}

const DateOrDates: React.FC<Props> = ({ dateIsRange, required, formPath, control, ...props }) => {


  return (
    <Box>

      <Controller
        name={`${formPath}.0`}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <PrimaryTextInput
            name={dateIsRange ? "Start Date" : "Date"}
            label={dateIsRange ? "Start Date" : "Date"}
            onChange={(event: any) => {onChange(event)}}
            value={value || ''}
            required={required}
            multiline={true}
          />
        )}
      />

      

      {dateIsRange && 
        <Controller
          name={`${formPath}.1`}
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <PrimaryTextInput
              name="End Date"
              label="End Date"
              onChange={(event: any) => {onChange(event)}}
              value={value || ''}
              required={required}
              multiline={true}
            />
          )}
        />
      }
    </Box>
  );
};

export default DateOrDates;
